//sidebar width > 768
.sidebar {
  min-width: 255px;
  max-width: 255px;
  height: calc(100vh - 50px);
  background: #32373d;
  color: #fff;
  -webkit-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s;
  position: relative;
}

.sidebar ul.nav li .nav-link {
  margin: 2px 10px;
}
.sidebar ul.nav li .nav-link:hover {
  background: rgba(50, 55, 61, 0.3);
}
.sidebar ul.nav li .nav-link.active {
  background: #32373d;
}
.sidebar ul.nav li .nav-link.active.active-link {
  background: none;
  font-weight: 700;
}

.sidebar ul.nav li.dropdown ul.drop-item {
  display: block;
  position: relative;
  margin-right: -10px;
  width: 100%;
}

.sidebar ul.nav li.dropdown:hover ul.dropdown-menu {
  display: block;
  background: #32373d;
  position: absolute;
  top: 0;
  right: -160px;
}
.sidebar ul.nav li.dropdown:hover ul.dropdown-menu .nav-link:hover {
 background-color: rgb(33,37,41);
}
.sidebar ul li>ul li a span {
  padding-left: 36px;
}
.sidebar ul li ul.dropdown-menu li a {
  padding-left: 0;
}
.sidebar ul li ul.dropdown-menu li a span {
  padding-left: 10px;
}

.sidebar ul li {
  font-size: 14px
}

.sidebar ul li>ul {
  margin-left: 10px
}

.sidebar ul li>ul li {
  font-size: 12px
}

.sidebar ul li a {
  padding: 15px 15px;
  margin: 2px 0;
  display: block;
}
.sidebar ul li button {
  padding: 15px 15px;
  margin: 2px 0;
  display: block;
}

.sidebar ul li a div {
  min-width: 25px;
  max-width: 25px;
}

.sidebar ul li button {
  max-width: 100%;
  padding: 15px 15px;
  margin: 2px 0;
  display: block;
}

.sidebar ul li button div {
  min-width: 25px;
  max-width: 25px;
}

.sidebar.mini {
  min-width: 75px;
  max-width: 75px;

  ul.nav li.dropdown .nav-link.active-link {
    background: #32373d;
  }
  ul.nav li .nav-link {
    max-width: 100%;
  }
  ul.nav li .nav-link span {
    margin: 0;
  }
  ul.nav li.dropdown:hover ul.dropdown-menu {
    z-index: 100;
    display: block;
    background: #32373d;
    position: absolute;
    padding: 0;
    margin: 0;
    top: 0;
    right: -160px;
  }
  ul.nav li.dropdown ul.drop-item {
    display: none;
  }

  ul li ul.dropdown-menu li.nav-item{
    padding: 0;
  }
  ul li > ul li a span {
    padding-left: 10px;
  }
  ul li button div .toggle-icon{
    transform: rotate(180deg);
  }
  ul.nav li.dropdown:hover ul.drop-item {
    z-index: 100;
    display: block;
    background: #32373d;
    min-width: 10rem;
    position: absolute;
    top: 0;
    right: -160px;
    padding: 0;
    margin: 0;
    border-radius: 6px;
  }
  ul.nav li.dropdown:hover ul.drop-item {
    margin: 2px 0;
  }
  ul.nav li.dropdown ul.drop-item .nav-link:hover {
    background-color: rgb(33,37,41);
  }
  ul li ul.flex-column .nav-item {
    margin: 0;
    padding: 0 0;
    width: 100%;
  }
  ul li ul.flex-column .nav-item .nav-link {
    padding: 14px 0;
  }
  ul.nav li.dropdown ul.drop-item .nav-item span {
    margin-left: 8px;
  }

  .btn.btn-primary {
    height: 30px
  }

  .btn.btn-primary:after {
    content: "\f054";
    left: 2px;
    top: 2px;
  }
  ul li a {
    margin: 2px 0;
  }
  ul li a div span {
    display: none;
  }
  ul li button div span {
    display: none;
  }
}

//OverSidebar < 768
.sidebar-over {
  position: absolute;
  z-index: 100;
  ul.nav li.dropdown:hover ul.dropdown-menu {
    display: none;
  }
}

.sidebar-over.hide {
  margin-left: -255px;

  ul li a {
    display: none;
  }
}
