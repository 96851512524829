.integration {
  position: relative;
  opacity: 0.4;
}

.integration-connected::before {
  font: var(--fa-font-solid);
  content: "\f058";
  color: #23a40e;
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 100;
}

.integration.integration-selected,
.integration:hover {
  opacity: 1;
}
