.timepicker {
  border: var(--bs-border-width) solid var(--bs-border-color);
  min-height: calc(1.5em + 0.5rem + calc(var(--bs-border-width) * 2));
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.25rem;
  text-align: center;
  width: 100px;

  .hh, .mm {
    width: 30px;
    outline: none;
    border: none;
    text-align: center;
  }
}
