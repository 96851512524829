.form-escalation-policy {
  .rule-add {
    font-size: 12px;
  }

  .repeats-rule {
    .repeat-rule {
      width: 50px;
    }
  }
}
