.responder-group {
  position: relative;
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  flex-direction: row-reverse;
  justify-content: flex-end;
  padding: 0 0 0 12px;

  .responder-group-item {
    position: relative;
    margin-left: -12px;
    cursor: pointer;
  }

  .responder-avatar, .responder-team-ico {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    font-weight: 400;
    width: 32px;
    height: 32px;
    text-transform: uppercase;
    position: relative;
    flex-shrink: 0;
    white-space: normal;

    .responder-avatar-bordered {
      border-radius: 50%;
      border: 1px solid var(--bs-dark);
    }

    .responder-group-remove {
      display: none;
    }
  }
    .responder-team-ico {
      background: var(--bs-success);
      color: var(--bs-white);
    }
      .responder-team-ico {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      width: 34px;
      height: 34px;
      background: var(--bs-success);
      color: var(--bs-white);
    }

  .responder-avatar:hover {
    .responder-group-remove {
      position: absolute;
      z-index: 1;
      width: 16px;
      height: 16px;
      top: -4px;
      right: -4px;
      border-radius: 50%;
      border: 1px solid var(--bs-dark);
      background: var(--bs-white);
      display: none;
      cursor: pointer;
      padding: 2px;
    }

    .responder-group-remove.icon {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-grow: 0;
      flex-shrink: 0;
      color: var(--bs-danger);
    }
  }
}
