.alert-status {
  width: 10px;
  height: 10px;
  border-radius: 3px;
}

.alert-name {
  min-width: 250px;
  max-width: 250px;
}

.alert-integration {
  min-width: 200px;
  max-width: 200px;
  font-size: 14px;
}

.alert-responder {
  width: 10px;
  min-width: 10px;
  text-align: center;
}

.alert-created {
  width: 140px;
  text-align: end;
}

.alert-priority {
  margin-top: -2px
}

.alert-priority i {
  font-size: 13px;
}

.activity-container {
  display: block;
  position: relative;
  font-family: Roboto, sans-serif;
}

.alert-status-change {
  width: 27px;
}

.activity-container ul.activity-list {
  margin: 2.5em 0;
  padding: 0;
  display: inline-block;
}

.activity-container ul.activity-list li {
  list-style: none;
}

.activity-container ul.activity-list li .timeline-item .active-icon .active-icon-fa {
  list-style: none;
  min-height: 40px;
  height: calc(100% - 30px);
  border-left: 1px solid #aaa;
  margin: 5px 0 0 7px;
  padding: 0 0 5px 15px;
  position: relative;
}

.activity-container ul.activity-list li:last-child .timeline-item .active-icon .active-icon-fa:last-child {
  border-left: 0;
  left: 1px;
}

ul.activity-list li .timeline-item .timestamp {
  position: relative;
  min-width: 100px;
}

.item-message {
  width: 100%;
}

.event-icon {
  font-size: 16px;
}

.alert-count {
  min-width: 25px;
  padding: 0 3px;
  align-items: center;
  text-align: center;
  border-radius: 3px;
  border: 1px solid #aaa;
}

.alert-number {
  width: 50px;
  padding: 0 3px;
  align-items: center;
  text-align: center;
}
