// Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "~bootstrap/scss/functions";

// Change variables
@import "variables";

// Include remainder of required Bootstrap stylesheets
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

// Include any optional Bootstrap components as you like
@import "~bootstrap";

// Add additional custom code here

@import "sidebar";
@import "alerts";
@import "preloader";

.header-content {
  height: 50px;
}

.cursor-pointer {
  cursor: pointer;
}
.container-info {
  height: calc(100vh - 50px);
  overflow-y: auto;
}

.overflow-info {
  max-height: 95vh;
  overflow-y: auto;
}
.breadcrumb-button {
  color: #32373d;
  font-size: 18px;
  padding: 0 5px;
  border-radius: 4px;
}
.breadcrumb-button:hover {
  background: #ececef;
}

.table-light {
  font-size: 10px;
  font-weight: 500;
  font-family: Roboto, sans-serif;
  color: #c4c4c4;
}

.sticky-top {
  z-index: 1;
}

.integration-rules {
  max-width: 800px;
}

.rotation-list {
  width: 100%;
  height: 30px;
}

.rotation-header {
  width: 10%;
  padding-top: 29px;
}

.rotation-schedule {
  width: 90%;
}

.rotations-layers {
  position: absolute;
  top: 30px;
  left: inherit;
}

.rotations-layer {
  position: absolute;
  height: 31px;
  top: inherit;
  left: inherit;
}

.rotation-item {
  height: 30px;
}

.rotation-period {
  position: absolute;
}
